<template>
  <div class="container pb-5">
    <div class="row">
      <div class="col-8 offset-2">
        <div v-show="etapaAtual == 'etapaPlanos'" class="text-center">

          <div v-show="!loading">
            <h5 class="font-weight-light">
              <b> Qual plano fica ideal para você e seu cliente? </b>
            </h5>
            <hr class="mt-3 mb-3" />
            <div class="text-center mb-4">
              <div class="toggle-planos">
                <span>
                  <button @click.prevent="btnPlanoMensal()" type="button" class="btn btn-planos-periodo"
                    :class="activeMensal">
                    Mensal
                  </button></span>
                <span>
                  <button @click.prevent="btnPlanoAnual()" type="button" class="btn btn-planos-periodo"
                    :class="activeAnual">
                    Anual
                  </button></span>
              </div>
            </div>
            <div class="card mb-1 box-shadow" v-for="(plano, index) in lstPlanos" :key="'plano' + index"
              :class="{ 'border-warning': verificaPlanoRecomendado(plano) }">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 text-center text-md-left mb-3">
                    <div v-if="plano.id == config.ID_PLANO_PERFORMANCE">
                      <h5>Bike Performance</h5>
                      <ul class="plano-detalhes text-left">
                        <li v-for="(item,
                          index) in config.DESCRICAO_PLANO_PERFORMANCE" :key="'p' + index">
                          {{ item }}
                        </li>
                        <li v-if="coberturaAcessorio">
                          {{ config.DESCRICAO_PLANO_ACESSORIO.DESCRICAO }}
                          {{ cliente.nomeAcessorio }}
                        </li>
                      </ul>
                    </div>

                    <div v-if="plano.id == config.ID_PLANO_ATIVO">
                      <h5>Bike Ativo</h5>
                      <ul class="plano-detalhes text-left">
                        <li v-for="(item, index) in config.DESCRICAO_PLANO_ATIVO" :key="'a' + index">
                          {{ item }}
                        </li>
                        <li v-if="coberturaAcessorio">
                          {{ config.DESCRICAO_PLANO_ACESSORIO.DESCRICAO }}
                          {{ cliente.nomeAcessorio }}
                        </li>
                      </ul>
                    </div>

                    <div v-if="plano.id == config.ID_PLANO_URBANO">
                      <h5>Bike Urbano</h5>
                      <ul class="text-left plano-detalhes">
                        <li v-for="(item, index) in config.DESCRICAO_PLANO_URBANO" :key="'u' + index">
                          {{ item }}
                        </li>
                        <li v-if="coberturaAcessorio">
                          {{ config.DESCRICAO_PLANO_ACESSORIO.DESCRICAO }}
                          {{ cliente.nomeAcessorio }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-6 text-center text-muted">
                    <div class="badge badge-pill badge-warning " v-if="verificaPlanoRecomendado(plano)">
                      Plano recomendado
                    </div>

                    <div v-show="planoAnual" class="texto-desconto-desktop">
                      DE: {{ formatacaoMoeda2(plano.valorAnualComAssistencia24hMKT) }}
                    </div>
                    <div v-show="planoSemestral" class="texto-desconto-desktop">
                      DE: {{ formatacaoMoeda2(plano.valorSemestralComAssistencia24hMKT) }}
                    </div>
                    <h4 class="card-title pricing-card-title">
                      <span style="font-size:14px;">Por: </span><span v-if="planoAnual">{{
                        formatacaoMoeda(plano.valorAnualComAssistencia24h)
                      }}
                      </span>
                      <span v-else-if="planoSemestral">{{
                        formatacaoMoeda(plano.valorSemestralComAssistencia24h)
                      }}
                      </span>
                      <span v-else>{{
                        formatacaoMoeda(plano.valorMensalComAssistencia24h)
                      }}
                      </span>
                      <small v-if="planoAnual" class="text-muted">/ ano</small>
                      <small v-else-if="planoSemestral" class="text-muted">/ sem.</small>
                      <small v-else class="text-muted">/ mês</small>
                    </h4>
                    <button v-if="planoAnual" class="btn btn-block btn-success" @click="
                      escolherPlanoSeguro(
                        plano.id,
                        plano.valorAnualComAssistencia24h,
                        plano.valorAcessorioAnual
                      )
                      ">
                      Quero assinar
                    </button>
                    <button v-else-if="planoSemestral" class="btn btn-block btn-success" @click="
                      escolherPlanoSeguro(
                        plano.id,
                        plano.valorSemestralComAssistencia24h,
                        plano.valorAcessorioSemestral
                      )
                      ">
                      Quero assinar
                    </button>
                    <button v-else class="btn btn-block btn-success" @click="
                      escolherPlanoSeguro(
                        plano.id,
                        plano.valorMensalComAssistencia24h,
                        plano.valorAcessorioMensal
                      )
                      ">
                      Quero assinar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6 text-left">
                <button class="btn btn-link" @click.prevent="voltar">
                  <i class="fas fa-chevron-left"></i> Voltar
                </button>
              </div>

            </div>
          </div>
        </div>

        <div v-show="etapaAtual == 'etapaPlanosAp'" class="text-center">
          <div v-show="!loading">
            <h5 class="font-weight-light">
              <b>Muito bom! Agora vamos pensar em você ciclista! </b>
            </h5>
            <hr class="mt-3 mb-3" />
            <div class="alert alert-info">
              <p>
                Chegou o seguro de acidentes pessoais para ciclistas! Agora você
                fica coberto também. E o melhor, tudo na mesma assinatura e sem
                burocracia.
              </p>
            </div>
            <div class="card mb-1 box-shadow" v-for="(plano, index) in lstPlanosOpcional" :key="'plano' + index"
              :class="plano.id == config.ID_PLANO_OURO ? 'border-warning' : ''">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6 text-center text-md-left mb-3">
                    <div v-if="plano.id == config.ID_PLANO_OURO">
                      <h5>{{ plano.id }}</h5>

                      <strong>
                        Indenização:
                        {{ config.DESCRICAO_PLANO_AP_OURO_IDENIZACAO }}</strong>
                      <div class="mb-1 idenizacao">
                        Aplicada no caso de:
                      </div>
                      <ul class="plano-detalhes text-left">
                        <li v-for="(item,
                          index) in config.DESCRICAO_PLANO_AP_OURO" :key="'p' + index">
                          {{ item }}
                        </li>
                      </ul>
                    </div>

                    <div v-if="plano.id == config.ID_PLANO_PRATA">
                      <h5>{{ plano.id }}</h5>
                      <strong>Indenização:
                        {{ config.DESCRICAO_PLANO_AP_PRATA_IDENIZACAO }}
                      </strong>
                      <div class="mb-1 idenizacao">
                        Aplicada no caso de:
                      </div>
                      <ul class="plano-detalhes text-left">
                        <li v-for="(item,
                          index) in config.DESCRICAO_PLANO_AP_PRATA" :key="'a' + index">
                          {{ item }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-md-6 text-center text-muted">
                    <div class="badge badge-pill badge-warning " v-if="plano.id == config.ID_PLANO_OURO">
                      Plano recomendado
                    </div>
                    <br />
                    <h4 class="card-title pricing-card-title">
                      <span v-if="planoAnual">{{
                        formatacaoMoeda(plano.valorAnualComAssistencia24h)
                      }}
                      </span>
                      <span v-else-if="planoSemestral">{{
                        formatacaoMoeda(plano.valorSemestralComAssistencia24h)
                      }}
                      </span>
                      <span v-else>{{
                        formatacaoMoeda(plano.valorMensalComAssistencia24h)
                      }}
                      </span>
                      <small class="text-muted">/ mês</small>
                    </h4>

                    <button class="btn btn-block btn-success" v-if="planoAnual" @click="
                      escolherPlanoSeguroAp(
                        plano.id,
                        plano.valorAnualComAssistencia24h
                      )
                      ">
                      Contratar
                    </button>
                    <button class="btn btn-block btn-success" v-else-if="planoSemestral" @click="
                      escolherPlanoSeguroAp(
                        plano.id,
                        plano.valorSemestralComAssistencia24h
                      )
                      ">
                      Contratar
                    </button>
                    <button class="btn btn-block btn-success" v-else @click="
                      escolherPlanoSeguroAp(
                        plano.id,
                        plano.valorMensalComAssistencia24h
                      )
                      ">
                      Contratar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-6 text-left">
                <button class="btn btn-link" @click.prevent="back('etapaPlanos')">
                  <i class="fas fa-chevron-left"></i> Voltar
                </button>
              </div>
              <div class="col-6 text-right">
                <button class="btn btn-link" @click.prevent="etapaAtual = 'etapaDadosClienteCPF'">
                  Continuar <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-show="etapaAtual == 'etapaDadosClienteCPF'">
          <h5 class="font-weight-light">
            <b>
              Informe o CPF do cliente que está comprando o seguro:
            </b>
          </h5>
          <hr class="mt-3 mb-3" />
          <div class="alert alert-info">
            <p>
              O CPF do cliente segurado é necessário para simulação e
              <b>contratação do seguro</b>.
            </p>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <form id="etapaCPF">
              <div class="form-group">
                <ValidationProvider name="CPF" rules="required|cpf" v-slot="{ errors, classes }">
                  <input type="text" class="form-control" v-model="cpf" :class="classes" v-mask="'###.###.###-##'"
                    placeholder="000.000.000-00" autocomplete="nope" />
                  <span class="invalid-feedback">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <hr />
              <div class="row">
                <div class="col-6 text-left">
                  <button class="btn btn-link" @click.prevent="back('etapaPlanosAp')">
                    <i class="fas fa-chevron-left"></i> Voltar
                  </button>
                </div>
                <div class="col-6 text-right">
                  <button class="btn btn-link" :disabled="invalid" @click.prevent="buscarDadosCliente()">
                    Continuar <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>

        <div v-show="etapaAtual == 'etapaDadosCliente'">
          <div v-show="!loading">
            <h5 class="font-weight-light">
              <b> Preencha os dados do cliente </b>
            </h5>
            <hr class="mt-3 mb-3" />

            <ValidationObserver v-slot="{ invalid }">
              <form id="etapaDadosCliente">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <label>CPF</label>
                      <ValidationProvider name="CPF" rules="required|cpf" v-slot="{ errors, classes }">
                        <input type="text" class="form-control" v-model="cliente.cpf" :class="classes"
                          v-mask="'###.###.###-##'" placeholder="000.000.000-00" autocomplete="nope" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Nome</label>
                      <ValidationProvider name="Nome" rules="required" v-slot="{ errors, classes }">
                        <input type="text" class="form-control" v-model="cliente.nome" :class="classes"
                          placeholder="Nome" autocomplete="nope" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Sobrenome</label>
                      <ValidationProvider name="Sobrenome" rules="required" v-slot="{ errors, classes }">
                        <input type="text" class="form-control" v-model="cliente.sobrenome" :class="classes"
                          placeholder="Sobrenome" autocomplete="nope" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Email</label>
                      <ValidationProvider ref="email" name="Email" rules="required|email" v-slot="{ errors, classes }">
                        <input type="text" class="form-control" @blur="verificarEmail()" v-model="cliente.email"
                          :class="classes" placeholder="Email" autocomplete="nope" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Nascimento</label>
                      <ValidationProvider ref="nascimento" name="Data de Nascimento"
                        rules="required|data_nascimento|data_maior_idade" v-slot="{ errors, classes }">
                        <input type="tel" class="form-control" :class="classes" v-model="nascimento"
                          v-mask="'##/##/####'" autocomplete="nope" placeholder="Nascimento" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>Celular (whatsapp)
                        <i class="fas fa-question-circle text-info" id="popover-telefone"></i>
                      </label>
                      <b-popover target="popover-telefone" triggers="hover" placement="bottomright">
                        <div>
                          Atenção. Confirme o número do celular do cliente para
                          enviarmos o link de vistoria online para aprovação do
                          seguro
                        </div>
                      </b-popover>
                      <ValidationProvider name="Telefone" :rules="'required'" v-slot="{ errors, classes }">
                        <input type="text" class="form-control" v-model="cliente.telefone" :class="classes"
                          v-mask="'(##) #####-####'" placeholder="Telefone" autocomplete="nope" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>RG</label>
                      <ValidationProvider name="RG" rules="required" v-slot="{ errors, classes }">
                        <input type="text" class="form-control" v-model="cliente.rg" :class="classes" placeholder="RG"
                          autocomplete="nope" />
                        <span class="invalid-feedback">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-6 text-left">
                    <button class="btn btn-link" @click.prevent="back('etapaDadosClienteCPF')">
                      <i class="fas fa-chevron-left"></i> Voltar
                    </button>
                  </div>
                  <div class="col-6 text-right">
                    <button class="btn btn-link" @click.prevent="etapaDadosBike()" :disabled="invalid">
                      Continuar <i class="fas fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>

        <div v-show="etapaAtual == 'etapaEnderecoBike'">
          <h5 class="font-weight-light">
            <b> Preencha os dados de endereço </b>
          </h5>
          <hr class="mt-3 mb-3" />
          <div class="alert alert-info">
            Agora precisamos saber o endereço onde reside a sua bike. Favor
            prencher os dados a baixo.
          </div>

          <ValidationObserver v-slot="{ invalid }">
            <form id="etapaEnderecoBike">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>CEP</label>
                    <ValidationProvider name="CEP" rules="required|min:9" v-slot="{ errors, classes }">
                      <input type="tel" class="form-control" :class="classes" v-model="cep" v-mask="'#####-###'"
                        autocomplete="nope" placeholder="CEP" />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div v-if="loadingCep">
                      <small>Carregando...</small>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Cidade</label>
                    <ValidationProvider name="cidade" rules="required|min:3" v-slot="{ errors, classes }">
                      <input type="text" class="form-control" :class="classes" v-model="cliente.cidade"
                        autocomplete="nope" maxlength="50" />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Estado</label>
                    <ValidationProvider name="estado" rules="required" v-slot="{ errors, classes }">
                      <select class="form-control" v-model="cliente.uf" :class="classes">
                        <option value>Selecione...</option>
                        <option>AC</option>
                        <option>AL</option>
                        <option>AM</option>
                        <option>AP</option>
                        <option>BA</option>
                        <option>CE</option>
                        <option>DF</option>
                        <option>ES</option>
                        <option>GO</option>
                        <option>MA</option>
                        <option>MG</option>
                        <option>MS</option>
                        <option>MT</option>
                        <option>PA</option>
                        <option>PB</option>
                        <option>PE</option>
                        <option>PI</option>
                        <option>PR</option>
                        <option>RJ</option>
                        <option>RN</option>
                        <option>RO</option>
                        <option>RR</option>
                        <option>RS</option>
                        <option>SC</option>
                        <option>SE</option>
                        <option>SP</option>
                        <option>TO</option>
                      </select>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label>Endereço</label>
                    <ValidationProvider name="endereço" rules="required|min:3" v-slot="{ errors, classes }">
                      <input type="text" class="form-control" :class="classes" v-model="cliente.endereco"
                        autocomplete="nope" maxlength="100" />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Número</label>
                    <ValidationProvider name="número" rules="required|min:1" v-slot="{ errors, classes }">
                      <input type="tel" v-model="cliente.numeroEndereco" class="form-control" :class="classes"
                        autocomplete="nope" maxlength="10" v-mask="'##########'" />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-8">
                  <div class="form-group">
                    <label>Complemento</label>
                    <input type="text" class="form-control" v-model="cliente.complementoEndereco" autocomplete="nope"
                      maxlength="100" />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Bairro</label>
                    <ValidationProvider name="bairro" rules="required|min:3" v-slot="{ errors, classes }">
                      <input type="text" class="form-control" :class="classes" v-model="cliente.bairroEndereco"
                        autocomplete="nope" maxlength="100" />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-6 text-left">
                  <button class="btn btn-link" @click.prevent="back('etapaDadosCliente')">
                    <i class="fas fa-chevron-left"></i> Voltar
                  </button>
                </div>
                <div class="col-6 text-right">
                  <button class="btn btn-link" @click.prevent="etapaEnderecoBike()" :disabled="invalid">
                    Continuar <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>

        <div v-show="etapaAtual == 'etapaDadosBike'">
          <h5 class="font-weight-light">
            <b> Me fale um pouco sobre a bike... </b>
          </h5>
          <hr class="mt-3 mb-3" />
          <div class="alert alert-info">
            <p>
              Agora vamos precisar de alguns dados da bike que será segurada.
            </p>
          </div>
          <ValidationObserver v-slot="{ invalid }">
            <form id="etapaDadosBike">
              <div class="row">
                <div class="col-6">
                  <b-form-group label="Marca">
                    <ValidationProvider name="marca" rules="required" v-slot="{ errors, classes }">
                      <input type="text" class="form-control" v-model="marcaBike.nome" readonly :class="classes" />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Modelo</label>
                    <ValidationProvider name="modelo" rules="required" v-slot="{ errors, classes }">
                      <input type="text" class="form-control" placeholder="Descreva o modelo"
                        v-model="cliente.modeloBike" readonly :class="classes" maxlength="100" />
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Ano de fabricação</label>
                    <ValidationProvider name="ano" rules="required" v-slot="{ errors, classes }">
                      <select class="form-control" :class="classes" v-model="ano">
                        <option :value="undefined">Selecione...</option>
                        <option v-for="(item, index) in anoBikes" :key="'ano' + index" :value="item.anoBike">
                          {{ item.anoBike }}
                        </option>
                      </select>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Modalidade</label>

                    <ValidationProvider name="modalidade" rules="required" v-slot="{ errors, classes }">
                      <select class="form-control" :class="classes" v-model="modalidadeBike">
                        <option :value="undefined" selected>Selecione...</option>
                        <option v-for="(item, index) in lstModalidade" :key="'modalidade' + index" :value="item">
                          {{ item.nome }}
                        </option>
                      </select>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>

                    <div v-if="loadingModalidade">Carregando...</div>
                  </div>
                </div>
                <div class="col-6">
                  <b-form-group label="Informe o valor (sem os centavos)" aria-readonly="true">
                    <ValidationProvider name="valor" :rules="'required|valor_bike'" v-slot="{ errors, classes }">
                      <money :disabled="true" v-model="cliente.valorBike" v-bind="{
                        decimal: ',',
                        thousands: '.',
                        prefix: 'R$ ',
                        suffix: '',
                        precision: 0,
                      }" :class="['form-control', classes]"></money>
                      <span class="invalid-feedback">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-form-group>
                </div>
              </div>
              <hr />
              <div class="row">
                <div class="col-6 text-left">
                  <button class="btn btn-link" @click.prevent="back('etapaEnderecoBike')">
                    <i class="fas fa-chevron-left"></i> Voltar
                  </button>
                </div>
                <div class="col-6 text-right">
                  <button class="btn btn-success" :disabled="invalid" @click.prevent="finalizarSeguroLojista">
                    Finalizar Cotação <i class="fas fa-chevron-right"></i>
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>

        <div v-show="etapaAtual == 'etapaObrigado'">
          <div class="container">
            <div class="text-center">
              <div class="mt-5">
                <h2 class="text-success">Cotação registrada com sucesso! <div class="step-circle-check filled">✓</div>
                </h2>
              </div>
              <div class="mt-5">
                <h6>Em alguns instantes, o link de pagamento será enviado para o WhatsApp do
                  cliente e o mesmo também estará disponível no painel ADMIN</h6>
              </div>
              <div class="mt-5">
                <button @click.prevent="painel" class="btn btn-success">
                  Acessar painel admin agora
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Loading :criandoSeguro="true" v-if="loading" />
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import auth from "../../auth";
import moment from "moment";
import captureError from "../../helpers/captureError";
import config from "../../config";
import formatacaoMoeda from "../../helpers/formatacaoMoeda";
import formatacaoMoeda2 from "../../helpers/formatacaoMoeda2";
import { Money } from "v-money";
import anoBikes from "../../data/anoBikesSeguro";

export default {
  components: {
    Money,
    Loading: () => import("../../components/Usuario/Loading.vue"),
  },
  data() {
    return {
      formatacaoMoeda,
      formatacaoMoeda2,
      config,
      anoBikes,
      etapaAtual: undefined,
      nascimento: undefined,
      modalidadeBike: undefined,
      marcaBike: undefined,
      // numeroSerieBike: undefined,
      modeloBike: undefined,
      loading: false,
      //Pagamento
      numCartao: undefined,
      validadeCartao: undefined,
      cvcCartao: undefined,
      nomeCartao: undefined,
      validandoCartao: false,

      //Endereço
      cep: undefined,
      loadingCep: false,
      exibirCamposEndereco: false,

      loadingFinalizar: false,
      cliente: {},
      loadingMarca: false,
      loadingPreco: false,
      lstPlanos: [],
      anoAtual: 0,
      ano: undefined,
      loadingModalidade: false,
      lstModalidade: [],
      seguro: {},
      cpf: undefined,
      idKakau: 0,

      //Plano anual
      planoAnual: false,
      idTipoAssinatura: undefined,
      activeMensal: "btn-success",
      activeAnual: undefined,
      valorPlanoMensal: [],

      //Seguro ap
      seguroAp: false,
      lstPlanosOpcional: [],

      //Plano semestral
      planoSemestral: false,
      activeSemestral: undefined,

      //Cobertura acessório
      coberturaAcessorio: false,

      tamanhoTela: undefined,
      ufCotacao: undefined
    };
  },
  created() {
    var userInfo = auth.getUserInfo();
    if (!userInfo.cnpj) return this.$router.replace("/parceiro");

    this.marcaBike = this.$route.params.marcaBike;
    this.cliente.modeloBike = this.$route.params.modeloBike;
    this.cliente.valorBike = this.$route.params.valorBike;
    this.coberturaAcessorio = this.$route.params.coberturaAcessorio;
    this.cliente.marcaAcessorio = this.$route.params.marcaAcessorio;
    this.cliente.nomeAcessorio = this.$route.params.nomeAcessorio;
    this.cliente.valorAcessorio = this.$route.params.valorAcessorio;
    this.cliente.ufCotacao = this.$route.params.ufCliente
    this.ufCotacao = this.$route.params.ufCliente;
    this.nascimento = this.$route.params.nascimento;
    if (
      this.marcaBike.nome == null ||
      this.marcaBike.nome == undefined ||
      this.cliente.valorBike == 0 ||
      !this.cliente.valorBike
    ) {
      return this.$router.replace("/dashboard/novo-seguro");
    }
    this.idKakau = this.$route.params.idKakau;
    this.popularItensKakau();

    this.buscarModalidades();
    this.cliente.cnpjLoja = userInfo.cnpj;
  },
  mounted() {
    this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_MENSAL;

    this.cliente.competicoesInternacionais = false;
    this.activeMensal = "btn-success";
    this.etapaPlanos();
  },
  methods: {
    voltar() {
      this.$router.replace("/dashboard/novo-seguro");
    },
    painel() {
      window.open(
        "https://app.bikeregistrada.com.br/admin",
        "_blank"
      );
    },
    copy() {
      return this.$toast.success("Link copiado com sucesso ✅");
    },
    btnPlanoAnual() {
      this.activeAnual = "btn-success";
      this.activeMensal = "";
      this.activeSemestral = "";
      this.planoAnual = true;
      this.planoSemestral = false;

      this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_ANUAL;
      return;
    },
    btnPlanoSemestral() {
      this.activeAnual = "";
      this.activeMensal = "";
      this.activeSemestral = "btn-success";
      this.planoSemestral = true;
      this.planoAnual = false;

      this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_SEMESTRAL;
      return;
    },

    btnPlanoMensal() {
      this.activeAnual = "";
      this.activeSemestral = "";
      this.activeMensal = "btn-success";
      this.planoAnual = false;
      this.planoSemestral = false;
      this.idTipoAssinatura = config.ID_TIPO_ASSINATURA_MENSAL;
      return;
    },
    popularItensKakau() {
      if (this.idKakau > 0) {
        this.cliente = this.$route.params.cliente;
        this.cpf = this.cliente.cpf;
        this.cep = this.cliente.cep;
        this.ano = this.cliente.anoBike;
        // this.numeroSerieBike = this.cliente.numeroSerieBike;
        if (!this.cliente.competicoesInternacionais) {
          this.cliente.competicoesInternacionais = false;
        }

        //busca somente quando tiver idkakau
        this.buscarModalidadesKakau();
      } else {
        return;
      }
    },
    alertaCheckout() {
      Swal.fire({
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        timer: 1500000,
        title: "",
        html: `<div  class="text-center">
            <lottie-player
              src="/anim/mountain_bike.json"
              background="transparent"
              speed="1.7"
              style="width: 72px; height: 72px; margin:0 auto;"
              autoplay
              loop
              class="mt-3"
            ></lottie-player>
            <div><h5>Legal, agora estamos recebendo seus dados.</h5></div>
            <div> Por favor, aguarde um instante até concluirmos.</div>
          </div>`,
      });
    },
    async verificarEmail() {
      var validation = await this.$refs.email.validate();

      if (!validation.valid) return;
      try {
        var response = await axios.get(
          `${config.API_URLV2}/cliente/verificarExisteClienteComCpfEmail?email=${this.cliente.email}&cpf=${this.cliente.cpf}`
        );
        if (!response.data.podeContinuar) {
          if (response.data.cliente) {
            Swal.fire({
              title: "Aviso!",
              icon: "info",
              html: `Olá, você está tentando usar um e-mail que já está vinculado a outro CPF: </b>  ${response.data.cliente}`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
              confirmButtonAriaLabel: "Eu entendi!",
            });
          } else {
            Swal.fire({
              title: "Aviso!",
              icon: "info",
              html: `O CPF informado já está vinculado a uma conta</b>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: false,
              confirmButtonText: '<i class="fa fa-thumbs-up"></i> Entendi!',
              confirmButtonAriaLabel: "Eu entendi!",
            });
          }
        }
      } catch (error) {
        captureError(error);
      }
    },
    async etapaPlanos() {
      this.etapaAtual = "etapaPlanos";
      this.loading = true;
      await this.buscarPlanos();
      this.loading = false;
    },


    async finalizarSeguroLojista() {
      try {
        this.cliente.formaPagamento = 'P';
        this.cliente.anoBike = this.ano;

        this.cliente.urlOrigem = document.referrer;
        this.cliente.urlPagina = window.location.href;

        this.cliente.idMarcaBike = this.marcaBike && this.marcaBike.id;
        this.cliente.marcaBike = this.marcaBike && this.marcaBike.nome;

        this.cliente.idModalidadeBike =
          this.modalidadeBike && this.modalidadeBike.id;
        this.cliente.modalidadeBike =
          this.modalidadeBike && this.modalidadeBike.nome;

        this.alertaCheckout();

        this.loadingFinalizar = true;

        this.cliente.nome = `${this.cliente.nome}`;
        this.cliente.sobrenome = `${this.cliente.sobrenome}`;
        this.cliente.telefoneInspecao = this.cliente.telefone;
        this.cliente.idTipoAssinatura = this.idTipoAssinatura;

        var response = await axios.post(`${config.API_URLV2}/seguro/finalizar/lojista`, this.cliente);

        this.seguro = response.data;

        this.loadingFinalizar = false;

        Swal.close();
        this.etapaAtual = "etapaObrigado";
      } catch (error) {
        this.validandoCartao = false;
        this.loadingFinalizar = false;
        //fechar modal de alerta
        Swal.close();
        var errorMessage = error?.response?.data?.message;
        if (error?.response?.data?.error) {
          Swal.fire({
            title: "Atenção",
            text:
              error?.response?.data?.error ||
              error?.response?.data?.error.toString(),
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else if (error?.response?.data?.erroCartao) {
          Swal.fire({
            title: "Atenção",
            text:
              "Não foi possível realizar a validação do seu cartão. Por favor verifique o saldo disponível ou tente utilizar outro cartão.",
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else if (error?.response?.data?.error) {
          this.$toast.error(error?.response?.data?.error, {
            duration: 8000,
            position: "top",
          });
        } else if (
          errorMessage ||
          (error &&
            error
              .toString()
              .toLowerCase()
              .indexOf("cartão") !== -1)
        ) {
          Swal.fire({
            title: "Atenção",
            text: errorMessage || error.toString(),
            icon: "warning",
            confirmButtonText: "Ok",
          });
        } else {
          captureError(
            error,
            this.cliente.email,
            this.cliente.cpf,
            this.cliente.nome
          );
          Swal.fire({
            title: "Não foi possível finalizar o seu seguro",
            text: "Tente novamente mais tarde ou contate o suporte.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    },

    async buscarModalidades() {
      try {
        this.loadingModalidade = true;

        var response = await axios.get(
          `${config.API_URLV2}/Modalidade/ListarModalidades`
        );
        this.lstModalidade = response.data;

        this.loadingModalidade = false;
      } catch (error) {
        captureError(error);

        Swal.fire({
          title: "Não foi possível obter as modalidades",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },

    async buscarModalidadesKakau() {
      try {
        var response = await axios.get(
          `${config.API_URLV2}/Modalidade/ListarModalidadesPorNome/${this.cliente.modalidadeBike}`
        );
        this.modalidadeBike = response.data;
      } catch (error) {
        captureError(error);

        Swal.fire({
          title: "Não foi possível obter as modalidades",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    async buscarDadosCliente() {
      this.loading = true;
      try {
        var response = await axios.get(
          `${config.API_URLV2}/cliente/BuscarClientePeloCpf/${this.cpf}`
        );

        var cliente = response.data;

        if (cliente.cpf != null) {
          this.cliente = { ...this.cliente, cpf: cliente.cpf };
        }

        if (cliente.email != null) {
          this.cliente = { ...this.cliente, email: cliente.email };
        }

        if (cliente.telefone != null) {
          this.cliente = { ...this.cliente, telefone: cliente.telefone };
        }

        var dataNsc = cliente.dataNascimento.split("T")[0];

        this.cliente.nome = cliente.nome.split(" ")[0];

        this.cliente.sobrenome = cliente.nome.split(" ")[1];

        this.nascimento = moment(dataNsc).format("DD/MM/YYYY");

        this.etapaAtual = "etapaDadosCliente";

        this.loading = false;
      } catch (error) {
        this.etapaAtual = "etapaDadosCliente";
        this.cliente = {
          ...this.cliente,
          cpf: this.cpf,
          nome: undefined,
          sobrenome: undefined,
          email: undefined,
          telefone: undefined,
          modeloBike: this.$route.params.modeloBike,
          valorBike: this.$route.params.valorBike,
          cnpjLoja: auth.getUserInfo().cnpj
        };

        this.cep = null;
        this.loadingCep = false;
        this.exibirCamposEndereco = false;
        this.loading = false;
      }
    },
    async buscarPlanos() {
      this.tamanhoTela = window.innerWidth < 768;
      try {
        let data = {
          valorBike: this.cliente.valorBike,
          valorAcessorio: this.cliente.valorAcessorio,
          ufCliente: this.ufCotacao,
          nascimento: this.nascimento
            ? moment(this.nascimento, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
        }
        let response = await axios.post(
          `${config.API_URLV2}/seguro/precosPlano`, data
        );

        this.lstPlanos =
          response.data.filter((item) => {
            if (
              item.id == config.ID_PLANO_OURO ||
              item.id == config.ID_PLANO_PRATA
            )
              return false;
            else return true;
          }) || [];

        this.lstPlanosOpcional =
          response.data.filter((item) => {
            if (
              item.id == config.ID_PLANO_OURO ||
              item.id == config.ID_PLANO_PRATA
            )
              return true;
            else return false;
          }) || [];

        return true;
      } catch (error) {
        captureError(error, this.cliente.email, undefined, this.cliente.nome);

        Swal.fire({
          title: "Não foi possível buscar os planos de seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });

        return false;
      }
    },

    etapaDadosBike() {
      this.cliente.nascimento = moment(this.nascimento, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );

      if (this.cliente.nascimento == "Invalid date") {
        this.$refs.nascimento.applyResult({
          errors: ["Data de nascimento inválida"],
          valid: false,
          failedRules: {},
        });
        return this.$toast.error("Preencha uma data de nascimento válida");
      }

      this.etapaAtual = "etapaEnderecoBike";
    },

    etapaEnderecoBike() {

      this.cliente.cep = this.cep;

      this.etapaAtual = "etapaDadosBike";
    },


    async back(step) {
      this.etapaAtual = step;
    },

    async escolherPlanoSeguro(plano, valorPlano, valorPlanoAcessorio) {
      this.cliente.plano = plano;
      this.cliente.valorPlano = valorPlano;
      this.cliente.valorPlanoAcessorio = valorPlanoAcessorio;
      this.etapaAtual = "etapaPlanosAp";
    },
    async escolherPlanoSeguroAp(planoOpcional, valorPlanoOpcional) {
      this.cliente.planoOpcional = planoOpcional;
      this.cliente.valorPlanoOpcional = valorPlanoOpcional;

      this.etapaAtual = "etapaDadosClienteCPF";
    },
    verificaPlanoRecomendado(itemPlano) {
      return this.cliente.competicoesInternacionais
        ? itemPlano.id == config.ID_PLANO_PERFORMANCE
        : itemPlano.id == config.ID_PLANO_ATIVO;
    },

    buscarEnderecoCep: function (cep) {
      //Busca apenas se tiver digitado o cep completo
      if (!cep || cep.length < 9) return;

      this.loadingCep = true;

      axios
        .get(`${config.API_URLV2}/cliente/BuscarEndereco?cep=${cep}`)
        .then((response) => {
          this.loadingCep = false;
          this.exibirCamposEndereco = true;

          //Se não veio os dados
          if (!response.data || !response.data.logradouro) {
            Swal.fire(
              "Atenção!",
              "Não encontramos o CEP informado em nossa base. Por favor, preencha o endereço completo.",
              "warning"
            );
            return;
          }

          this.cliente.endereco = response.data.logradouro;
          this.cliente.cidade = response.data.localidade;
          this.cliente.bairroEndereco = response.data.bairro;
          this.cliente.uf = response.data.uf;
        })
        .catch((response) => {
          this.loadingCep = false;
          this.exibirCamposEndereco = true;
          captureError(response);
        });
    },
  },
  watch: {
    cep: function (value) {
      clearTimeout(window["debounceCep"]);
      window["debounceCep"] = setTimeout(() => {
        this.buscarEnderecoCep(value);
      }, 700);
    },
  },
};

</script>

<style scoped>
.step-circle-check {
  width: 44px !important;
  height: 100% !important;
  border-radius: 50%;
  font-weight: bold;
  display: inline-block;
  border: 3px solid #23ad21;
  color: #23ad21;
  text-align: center;
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 1.5rem 3rem;
  font-size: 1.50rem;
  border-radius: 0.3rem;
  border-radius: 50px;
}

.btn-planos-periodo {
  border-radius: 100px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 12px;
}

.toggle-planos {
  background-color: #e7e7e7;
  border-radius: 100px;
  padding: 3px;
  display: inline-flex;
}

.step-line {
  border-left: 3px dashed #23ad21;
  width: 0;
  height: calc(100% - 36px);
  margin: 2px auto;
}

.step-circle-focus {
  border: 3px solid #ff7600;
  background-color: #ff7600;
  color: white;
}

.badge-orange {
  background-color: #ff7600;
  color: white;
}

.btn-orange {
  background-color: #ff7600;
  color: white;
}

.plano-detalhes {
  margin-bottom: 0;
  padding-left: 24px;
  font-size: 14px;
}

.texto-desconto-desktop {
  font-size: 12px;
  line-height: 19px;
  font-weight: 700;
  border-radius: 100px;
  padding-left: 3px;
  padding-right: 7px;
  margin-top: 3px;
  padding-top: 3px;
  text-decoration: line-through;
}

.idenizacao {
  font-size: 13px;
}
</style>
